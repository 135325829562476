import React from "react";
import ReactGA from "react-ga4";
const Home = React.lazy(() => import("./pages/Home"));
// import Home from "./pages/Home";
const Launchpad = React.lazy(() => import("./pages/Launchpad"));
const Scale2Success = React.lazy(() => import("./pages/Scale2Success"));
const Marketing = React.lazy(() => import("./pages/Marketing"));
const Formation = React.lazy(() => import("./pages/Formation"));
const Branding = React.lazy(() => import("./pages/Branding"));
const HumanCapital = React.lazy(() => import("./pages/HumanCapital"));
const Insights = React.lazy(() => import("./pages/Insights"));
const OurServices = React.lazy(() => import("./pages/OurServices"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const SevenStepsToStartupSuccess = React.lazy(() =>
  import("./pages/SevenStepsToStartupSuccess")
);
const CommonPitfalls = React.lazy(() => import("./pages/CommonPitfalls"));
const DigitalMarketing = React.lazy(() => import("./pages/DigitalMarketing"));
const Webpage = React.lazy(() => import("./webinar/src/Webpage"));

const CaseStudies = React.lazy(() => import("./pages/Projects"));

const Ideogram = React.lazy(() => import("./pages/casestudies/Ideogram"));
const Butlr = React.lazy(() => import("./pages/casestudies/Butlr"));
const Electry = React.lazy(() => import("./pages/casestudies/Electry"));
const Titos = React.lazy(() => import("./pages/casestudies/Titos"));
const SimplyGifted = React.lazy(() => import("./pages/casestudies/SimplyGifted"));
const SmartKhoj = React.lazy(() => import("./pages/casestudies/SmartKhoj"));
const Warehouse = React.lazy(() => import("./pages/casestudies/Warehouse"));
const Copperleaf = React.lazy(() => import("./pages/casestudies/Copperleaf"));
const CasinoPride = React.lazy(() => import("./pages/casestudies/CasinoPride"));
const DFU = React.lazy(() => import("./pages/casestudies/DFU"));
const PartyPapa = React.lazy(() => import("./pages/casestudies/PartyPapa"));

// import Launchpad from "./pages/Launchpad";
// import Scale2Success from "./pages/Scale2Success";
// import Marketing from "./pages/Marketing";
// import Formation from "./pages/Formation";
// import Branding from "./pages/Branding";
// import HumanCapital from "./pages/HumanCapital";
// import Insights from "./pages/Insights";
// import OurServices from "./pages/OurServices";
// import AboutUs from "./pages/AboutUs";
// import SevenStepsToStartupSuccess from "./pages/SevenStepsToStartupSuccess";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Calendly from "./pages/Calendly";
import { useEffect } from "react";
// import Titos from "./pages/casestudies/Titos";
// import Electry from "./pages/casestudies/Electry";
// import SimplyGifted from "./pages/casestudies/SimplyGifted";
// import Webpage from "./webinar/src/Webpage";
// import CommonPitfalls from "./pages/CommonPitfalls";
// import DigitalMarketing from "./pages/DigitalMarketing";

function App() {


  const TRACKING_ID = "G-C0QEZ464BE";
  // const TRACKING_ID = "G-V6BRKX7DY8";
  ReactGA.initialize(TRACKING_ID)

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home" });
  },[])

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://www.google-analytics.com/analytics.js';
  //   script.async = true;
  //   document.body.appendChild(script);
  // }, []);

  console.log("location====>>>>>", window.location.href);
  window.location.href !== "https://coppercodes.com/"
    ? sessionStorage.setItem("location", window.location.href)
    : console.log("wuii");

  return (
    <Router>
      <Routes>
        <Route path="/launchpad" exact element={<Launchpad />}></Route>
        <Route path="/scale2success" exact element={<Scale2Success />}></Route>
        <Route path="/marketing" exact element={<Marketing />}></Route>
        <Route path="/formation" element={<Formation />}></Route>
        <Route path="/branding" exact element={<Branding />}></Route>
        <Route path="/humancapital" exact element={<HumanCapital />}></Route>
        <Route path="/insights" exact element={<Insights />}></Route>
        <Route path="/ourservices" exact element={<OurServices />}></Route>
        <Route path="/aboutus" exact element={<AboutUs />}></Route>
        <Route path="/casestudies" exact element={<CaseStudies />}></Route>
        <Route path="/schedulecall" exact element={<Calendly/>}></Route>
        <Route
          path="/insights/seven_steps_to_startup_success"
          exact
          element={<SevenStepsToStartupSuccess />}
        ></Route>
        <Route
          path="/insights/common_pitfalls"
          exact
          element={<CommonPitfalls />}
        ></Route>
        <Route
          path="/insights/digital_marketing"
          exact
          element={<DigitalMarketing />}
        ></Route>

        <Route
          path="/casestudies/ideogram"
          exact
          element={<Ideogram />}
        ></Route>

        <Route path="/casestudies/butlr" exact element={<Butlr />}></Route>

        <Route path="/casestudies/smartkhoj" exact element={<SmartKhoj />}></Route>

        <Route path="/casestudies/titos" exact element={<Titos/>}></Route>
        <Route path="/casestudies/electry" exact element={<Electry/>}></Route>
        <Route path="/casestudies/simplygifted" exact element={<SimplyGifted/>}></Route>

        <Route path="/casestudies/warehouse" exact element={<Warehouse/>}></Route>
        <Route path="/casestudies/d4u" exact element={<DFU/>}></Route>
        <Route path="/casestudies/casinopride" exact element={<CasinoPride/>}></Route>
        <Route path="/casestudies/copperleaf" exact element={<Copperleaf/>}></Route>
        <Route path="/casestudies/partypapa" exact element={<PartyPapa/>}></Route>

        <Route path="/webinars" exact element={<Webpage />}></Route>
        <Route path="/" exact element={<Home />}></Route>
        <Route path="*"></Route>
      </Routes>
    </Router>
  );
}

export default App;
